import FormPage from '@/components/FormPage'
import { createGetParams, createPostParams } from '@/rest'
import {
  formatPhoneNumber,
} from '@/utils'
import security from '@/security'

const formTitle = 'Appoint New Dealer'
const defaultPin = 'D225'

export default {
  extends: FormPage,
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Dealership', disabled: false, to: '/dealership_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      appointmentDetails: {
        fields: {
          d225Pin: {
            name: defaultPin + ' PIN balance',
          },
          dpPin: {
            name: 'Dealer (DP) PIN balance',
          },
          dealerPin: {
            name: 'Dealer PIN balance',
          },
          bPin: {
            name: 'BPin balance',
          },
          sdPin: {
            name: 'Subdealer PIN balance',
          },
          nuPin: {
            name: 'NU PIN balance',
          },
          sldPin: {
            name: 'SLD PIN balance',
            hide: true,
          },
          dealerType: {
            component: () => import(/* webpackChunkName: "dealerTypeField" */ '@/components/DealerTypeField'),
            name: 'Dealer Type',
            list: [],
            props: {
              label: 'Select dealer type',
              required: true,
              rules: [val => !!val || 'Dealer type required!'],
            },
          },
          msisdn: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'Mobile number',
            props: {
              existing: true,
              noDealer: true,
              hint: 'Subscriber mobile number',
              label: 'Enter mobile number',
              presistentHint: true,
              required: true,
              rules: [val => !!val || 'Mobile number required!'],
            },
          },
          student: {
            component: () => import(/* webpackChunkName: "studentField" */ '@/components/StudentField'),
            name: 'Student status',
            hide: true,
            rules: [],
            required: false,
          },
          studentFront: {
            name: 'Student Front',
            hide: true,
            component: () => import(/* webpackChunkName: "fileInputField" */ '@/components/FileInputField'),
            props: {
              label: 'Upload front student card ',
              rules: [],
              required: false,
            },
          },
          studentId: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Student ID',
            hide: true,
            props: {
              hint: 'Exactly as in student card',
              label: 'Enter student ID',
              rules: [],
              required: false,
            },
          },
          uniState: {
            component: () => import(/* webpackChunkName: "iptastateField" */ '@/components/IptaStateField'),
            name: 'University state',
            hide: true,
            value: this.idstate,
            rules: [],
            required: false,
          },
          unipackage: {
            component: () => import(/* webpackChunkName: "iptapackagesField" */ '@/components/IptaPackagesField'),
            name: 'University',
            hide: true,
            props: {
              list: [],
              itemValue: 'idipta',
              itemText: 'iptaname',
              label: 'Select university',
              returnObject: true,
              required: false,
            },
            value: { itemText: null, itemValue: null },
          },
          yearOfStudy: {
            component: () => import(/* webpackChunkName: "yearStudyField" */ '@/components/YearStudyField'),
            name: 'Year Of Study',
            hide: true,
            props: {
              hint: 'Year of Study',
              label: 'Enter year of study',
              rules: [],
              required: false,
            },
          },
        },
        title: 'Appointment details',
      },
      submission: {
        fields: {
          notice: {
            component: () => import(/* webpackChunkName: "noticeDifferentUplineDealer" */ '@/components/NoticeDifferentUplineDealer'),
            name: 'Notice',
          },
          dealerType: {
            name: 'Dealer type',
          },
          msisdn: {
            name: 'Mobile number',
          },
          upline: {
            name: 'Upline Dealer',
          },
          name: {
            name: 'Name',
          },
          postalAddress: {
            name: 'Address',
          },
          city: {
            name: 'City',
          },
          postalCode: {
            name: 'Postal code',
          },
          state: {
            name: 'State',
          },
          student: {
            name: 'Student status',
            hide: true,
          },
          studentId: {
            name: 'Student ID',
            hide: true,
          },
          unipackage: {
            name: 'University',
            hide: true,
          },
          yearOfStudy: {
            name: 'Year Of Study',
            hide: true,
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        appointmentDetails: this.appointmentDetails,
        submission: this.submission,
      }
    },
    uni: function () {
      return this.appointmentDetails.fields.unipackage.value
    },
    d225Pin: function () {
      return this.appointmentDetails.fields.d225Pin
    },
    dpPin: function () {
      return this.appointmentDetails.fields.dpPin
    },
    dealerPin: function () {
      return this.appointmentDetails.fields.dealerPin
    },
    bPin: function () {
      return this.appointmentDetails.fields.bPin
    },
    sdPin: function () {
      return this.appointmentDetails.fields.sdPin
    },
    nuPin: function () {
      return this.appointmentDetails.fields.nuPin
    },
    sldPin: function () {
      return this.appointmentDetails.fields.sldPin
    },
  },
  mounted: function () {
    this.appointmentDetails.fields.d225Pin.props.rules.push(
      () => this.validateD225Pin(),
    )
    this.appointmentDetails.fields.dpPin.props.rules.push(
      () => this.validateDpPin(),
    )
    this.appointmentDetails.fields.dealerPin.props.rules.push(
      () => this.validateDealerPin(),
    )
    this.appointmentDetails.fields.bPin.props.rules.push(
      () => this.validateBPin(),
    )
    this.appointmentDetails.fields.sdPin.props.rules.push(
      () => this.validateSdPin(),
    )
    this.appointmentDetails.fields.nuPin.props.rules.push(
      () => this.validateNuPin(),
    )
    const params = createGetParams({
      dealership_pin: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      if (security.me.pureNuDealer()) {
        this.nuPin.error = null
        this.nuPin.value = response.data.nuPin
        this.appointmentDetails.fields.dealerType.list = ['NU']
        this.appointmentDetails.fields.bPin = {}
        this.appointmentDetails.fields.sdPin = {}
        this.appointmentDetails.fields.dealerPin = {}
        this.appointmentDetails.fields.dpPin = {}
        this.appointmentDetails.fields.d225Pin = {}
        this.appointmentDetails.fields.sldPin = {}
        this.appointmentDetails.fields.dealerType.value = 'NU'
        this.appointmentDetails.fields.dealerType.props.label = null
        if (response.data.nuPin <= 0) {
          this.nuPin.error = 'No NU PIN found!'
        }
      } else {
        this.d225Pin.error = null
        this.d225Pin.value = response.data.d225Pin
        this.appointmentDetails.fields.dealerType.list = []
        if (response.data.d225Pin > 0 || response.data.dpPin > 0 || response.data.bPin > 0 || response.data.sdPin > 0 || response.data.dealerPin > 0 || response.data.sldPin > 0) {
          if (response.data.d225Pin > 0) {
            this.d225Pin.error = null
            this.d225Pin.value = response.data.d225Pin
            this.appointmentDetails.fields.dealerType.list.push(defaultPin)
          }
          if (response.data.dpPin > 0) {
            this.dpPin.error = null
            this.dpPin.value = response.data.dpPin
            this.appointmentDetails.fields.dealerType.list.push('Dealer (DP)')
          } else {
            this.appointmentDetails.fields.dpPin = {}
          }
          if (response.data.bPin > 0) {
            this.bPin.error = null
            this.bPin.value = response.data.bPin
            this.appointmentDetails.fields.dealerType.list.push('Basic Dealer')
          } else {
            this.appointmentDetails.fields.bPin = {}
          }
          if (response.data.sdPin > 0) {
            this.sdPin.error = null
            this.sdPin.value = response.data.sdPin
            this.appointmentDetails.fields.dealerType.list.push('Subdealer')
          } else {
            this.appointmentDetails.fields.sdPin = {}
          }
          if (response.data.dealerPin > 0) {
            this.dealerPin.error = null
            this.dealerPin.value = response.data.dealerPin
            this.appointmentDetails.fields.dealerType.list.push('Dealer')
          } else {
            this.appointmentDetails.fields.dealerPin = {}
          }
          if (response.data.sldPin > 0) {
            this.sldPin.error = null
            this.sldPin.value = response.data.sldPin
            this.appointmentDetails.fields.dealerType.list.push('SLD')
          } else {
            this.appointmentDetails.fields.sldPin = {}
          }
        } else {
          this.appointmentDetails.fields.dpPin = {}
          this.appointmentDetails.fields.bPin = {}
          this.appointmentDetails.fields.sdPin = {}
          this.appointmentDetails.fields.dealerPin = {}
          this.appointmentDetails.fields.sldPin = {}
          this.appointmentDetails.fields.dealerType.value = defaultPin
          this.appointmentDetails.fields.dealerType.props.label = null
          // if (response.data.d225Pin <= 0) {
          //   this.d225Pin.error = 'No dealership PIN found!'
          // }
        }
        if (security.me.nuDealer()) {
          this.nuPin.error = null
          this.nuPin.value = response.data.nuPin
          this.appointmentDetails.fields.dealerType.list.push('NU')
        } else {
          this.appointmentDetails.fields.nuPin = {}
        }
      }
    }.bind(this)).catch(error => {
      if (!security.me.pureNuDealer()) {
        this.d225Pin.error = error.message
      }
      if (this.dpPin) {
        this.dpPin.error = error.message
      }
      if (this.dealerPin) {
        this.dealerPin.error = error.message
      }
      if (this.bPin) {
        this.bPin.error = error.message
      }
      if (this.sdPin) {
        this.sdPin.error = error.message
      }
      if (this.nuPin) {
        this.nuPin.error = error.message
      }
    })
  },
  watch: {
    'appointmentDetails.fields.dealerType.value': function (val) {
      this.appointmentDetails.fields.msisdn.props.noDealer = val !== 'NU'
      this.appointmentDetails.fields.msisdn.props.noNuDealer = val === 'NU'
      this.submission.fields.dealerType.value = this.appointmentDetails.fields.dealerType.value
    },
    'appointmentDetails.fields.msisdn.value': function (val) {
      this.submission.fields.msisdn.value = formatPhoneNumber(val)
      if (!val) return

      if ((val.length === 11 && val.startsWith('011')) || (val.length === 10 && !val.startsWith('011'))) {
        const params = createGetParams({
          target: this.appointmentDetails.fields.msisdn.value,
        })
        this.$rest.get('getProfile.php', params)
          .then(response => {
            this.submission.fields.dealerType.value = this.appointmentDetails.fields.dealerType.value
            this.submission.fields.upline.value = response.data.introducer
            this.submission.fields.name.value = response.data.name
            this.submission.fields.postalAddress.value = response.data.street
            this.submission.fields.city.value = response.data.city ? response.data.city : null
            this.submission.fields.postalCode.value = response.data.postal_code ? response.data.postal_code : null
            this.submission.fields.state.value = response.data.state ? response.data.state : null
            this.plan = response.data.black ? response.data.black : null
            this.submission.fields.notice = security.me.dealerId() === response.data.introducer ? {} : {
              component: () => import(/* webpackChunkName: "noticeDifferentUplineDealer" */ '@/components/NoticeDifferentUplineDealer'),
              name: 'Notice',
            }

            if (this.plan === 'MYSISWA35') {
              this.appointmentDetails.fields.student.hide = false
              this.appointmentDetails.fields.student.props.required = true
              this.appointmentDetails.fields.student.props.rules.push(
                val => !!val || 'Student status is required!',
              )
            } else {
              this.appointmentDetails.fields.student.hide = true
            }
          }).catch(() => {
            // ignore
          })
      }
    },
    'appointmentDetails.fields.student.value': function (val) {
      if (val === 'Y') {
        this.appointmentDetails.fields.uniState.hide = false
        this.appointmentDetails.fields.uniState.props.required = true
        this.appointmentDetails.fields.uniState.props.rules.push(
          val => !!val || 'University State is required!',
        )
        this.appointmentDetails.fields.studentFront.hide = false
        this.appointmentDetails.fields.studentFront.props.required = true
        this.appointmentDetails.fields.studentFront.props.rules.push(
          val => !!val || 'Student Front is required!',
        )
        this.appointmentDetails.fields.studentId.hide = false
        // this.appointmentDetails.fields.studentId.props.required = true
        // this.appointmentDetails.fields.studentId.props.rules.push(
        //   val => !!val || 'Student ID is required!',
        // )
        this.appointmentDetails.fields.unipackage.hide = false
        this.appointmentDetails.fields.yearOfStudy.hide = false
        // this.appointmentDetails.fields.yearOfStudy.props.required = true
        // this.appointmentDetails.fields.yearOfStudy.props.rules.push(
        //   val => !!val || 'Year Of Study required!',
        // )
        this.submission.fields.studentId.hide = false
        this.submission.fields.unipackage.hide = false
        this.submission.fields.yearOfStudy.hide = false
        this.submission.fields.student.value = val
      } else {
        this.appointmentDetails.fields.uniState.hide = true
        this.appointmentDetails.fields.studentFront.hide = true
        this.appointmentDetails.fields.studentId.hide = true
        this.appointmentDetails.fields.unipackage.hide = true
        this.appointmentDetails.fields.yearOfStudy.hide = true
        this.submission.fields.student.value = val
      }
    },
    'appointmentDetails.fields.studentId.value': function (val) {
      this.submission.fields.studentId.value = val
    },

    'appointmentDetails.fields.uniState.value': function (val) {
      const params = createGetParams({
        idstate: this.appointmentDetails.fields.uniState.value,
      })
      this.$rest.get('getUniversityName.php', params)
        .then(response => {
          this.appointmentDetails.fields.unipackage.props.list = response.data
        })
    },
    uni: function (val) {
      if (!val) return
      this.submission.fields.unipackage.value = val.iptaname
    },
    'appointmentDetails.fields.yearOfStudy.value': function (val) {
      this.submission.fields.yearOfStudy.value = val
    },
  },
  methods: {
    validateD225Pin: function () {
      return this.d225Pin <= 0 ? 'Insufficient ' + defaultPin + ' PIN!' : true
    },
    validateDpPin: function () {
      return this.dpPin <= 0 ? 'Insufficient Dealer (DP) PIN!' : true
    },
    validateDealerPin: function () {
      return this.dealerPin <= 0 ? 'Insufficient Dealer PIN!' : true
    },
    validateBPin: function () {
      return this.bPin <= 0 ? 'Insufficient BPin!' : true
    },
    validateSdPin: function () {
      return this.sdPin <= 0 ? 'Insufficient Subdealer PIN!' : true
    },
    validateNuPin: function () {
      return this.nuPin <= 0 ? 'Insufficient NU PIN!' : true
    },
    // Override
    async submit () {
      const params = createPostParams({
        action: 'appointDealer',
        payload: {
          target: this.appointmentDetails.fields.msisdn.value,
          type: this.appointmentDetails.fields.dealerType.value,
          plan: this.plan,
          student: this.appointmentDetails.fields.student.value,
          idiptas: this.appointmentDetails.fields.unipackage.value.idipta,
          studentId: this.appointmentDetails.fields.studentId.value,
          yearOfStudy: this.appointmentDetails.fields.yearOfStudy.value,
          studentIdFrontImage: this.appointmentDetails.fields.studentFront.value,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to appoint a new dealer was successfully submitted. ' +
            'Please ask the new dealer to create an XOX ID to login to OneSYS.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
